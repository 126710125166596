window.jQuery = window.$ = require('jquery');
var processInclude = require("base/js/util");

$(document).ready(function () {
    processInclude(require("../../minifiedJs/main"));
    processInclude(require("../js/custom/customWishlist"));
    processInclude(require("../js/custom/customNewsletter"));
    processInclude(require("./home/home"));
    processInclude(require("./custom"));
});
require('base/js/thirdParty/bootstrap');
setTimeout(() => {
    require('base/js/components/spinner');
}, 1000);
require('owl.carousel/src/js/owl.carousel');